import { FC, useEffect, useState, useCallback } from "react";
import "../../assets/scss/App.scss";
import AuthenticatedPage from "../AuthenticatedPage";
import UnauthenticatedPage from "../UnauthenticatedPage";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "../Routes";
import ErrorBoundary from "../ErrorBoundary";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  fetchConfigDataAndBrands,
  refreshSystemConfigData,
  MappedSystemState,
  mapSystemStateToProps,
  SystemDuck,
  SystemReadyStates,
  fetchSystemServiceMessages,
} from "../../store/systemStore";
import { AppState } from "../../store/app";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileDependencies,
  ProfileDuck,
  UserProfile,
} from "../../store/userProfileStore";
import { AuthService } from "../../services/AuthService";
import { mapAccountToUserProfile } from "../../util/profileHelper";
import SystemService from "../../services/SystemService";
import FullStory, { FullStoryAPI } from "react-fullstory";
import { selectInternalUser } from "store/system/selectors";
import { googleTagSetTrafficType } from "util/gtm";

/**
 *
 * @returns {JSX.Element}
 */
const App: FC<{}> = (): JSX.Element => {
  const { system, user } = useSelector<
    AppState,
    { user: UserProfile; system: MappedSystemState }
  >((state) => {
    return {
      system: mapSystemStateToProps(state.system),
      user: state.userProfile,
    };
  });
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const [systemTimer, setSystemTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);
  const [userProfileTimer, setUserProfileTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);
  const [userRefreshNeeded, setUserRefreshNeeded] = useState<boolean>(false);

  const requestUserDataRefresh = useCallback(() => {
    if (userProfileTimer === undefined && user.initialized) {
      let userDelay = 30 * 1000;
      let timer = setTimeout(() => {
        setUserProfileTimer(undefined);
        setUserRefreshNeeded(true);
      }, userDelay);
      setUserProfileTimer(timer);
    }
  }, [userProfileTimer, user.initialized]);

  useEffect(() => {
    new AuthService()
      .initialize((username: string) => {
        return new Promise<void>((resolve, reject) => {
          new SystemService()
            .recordLogon(username)
            .then((r) => {
              console.info(
                "last logon for user " + r.userName + " at " + r.lastLogon
              );
            })
            .catch((error: Error) => {
              console.error("unable record last logon for current user.");
              console.error(error ? error.message : error);
            });
        });
      })
      .then((auth) => {
        // @ToDo: sign out? unset userProfile auth variables
        //ProfileDuck.actions.SET_AUTHENTICATION(dispatch, auth);
        if (!auth) {
          SystemDuck.actions.update_readystate(
            dispatch,
            SystemReadyStates.Ready
          );
        } else {
          FullStoryAPI("identify", auth.homeAccountId, {
            displayName: auth.name || auth.username,
            email: auth.username,
          });
        }
      });
  }, [dispatch]);

  useEffect(() => {
    let username = new AuthService().getAndSetAccount()?.username;
    if (isAuthenticated && username) {
      dispatch(fetchConfigDataAndBrands());
    } else {
      dispatch(fetchSystemServiceMessages());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    let authAccount = new AuthService().getAndSetAccount();
    if (
      system.isReady &&
      authAccount &&
      system.currentUser &&
      system.currentUser.identityApiId !== user.identityApiId &&
      system.currentUser.lastLogon !== user.lastLogon
    ) {
      let mergedUser = mapAccountToUserProfile(authAccount, system.currentUser);
      ProfileDuck.actions.UPDATE_USER(dispatch, mergedUser);
    }
  }, [
    user.identityApiId,
    user.lastLogon,
    system.isReady,
    user.currentAccount,
    system.currentUser,
    dispatch,
  ]);

  useEffect(() => {
    const initialize = !user.loading && !user.initialized;
    if (initialize && system.isReady && user.identityApiId && isAuthenticated) {
      dispatch(getUserProfileDependencies());
    }
  }, [
    user.loading,
    user.initialized,
    system.isReady,
    user.identityApiId,
    isAuthenticated,
    dispatch,
  ]);

  // periodic system refresh
  useEffect(() => {
    if (systemTimer === undefined && system.isReady && user.initialized) {
      let delay = 5 * 60 * 1000;
      let timer = setInterval(() => {
        console.log("refreshing system data");
        dispatch(refreshSystemConfigData(true));
        requestUserDataRefresh();
      }, delay);
      setSystemTimer(timer);
    }
  }, [
    systemTimer,
    system.isReady,
    dispatch,
    user.initialized,
    requestUserDataRefresh,
  ]);

  // periodic user refresh
  useEffect(() => {
    if (userRefreshNeeded && !system.isRefreshing && system.currentUser) {
      console.log("refreshing user data");
      let authAccount = new AuthService().getAndSetAccount();
      if (authAccount) {
        let mergedUser = mapAccountToUserProfile(
          authAccount,
          system.currentUser
        );
        ProfileDuck.actions.UPDATE_USER(dispatch, mergedUser);
        dispatch(getUserProfileDependencies(true));
        setUserRefreshNeeded(false);
      }
    }
  }, [userRefreshNeeded, system.isRefreshing, system.currentUser, dispatch]);

  // Set traffic type based on if user is internal 
  useEffect(() => {
    const isInternal = selectInternalUser();
    if (isInternal) {
      googleTagSetTrafficType('internal');
    } else {
      googleTagSetTrafficType('external');
    }
  });

  return (
    <BrowserRouter>
      <UnauthenticatedPage>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </UnauthenticatedPage>
      <AuthenticatedPage>
        <ErrorBoundary>
          <>
            <AppRoutes />
            <FullStory org={process.env.REACT_APP_FULLSTORY_ORG || ""} />
          </>
        </ErrorBoundary>
      </AuthenticatedPage>
    </BrowserRouter>
  );
};
export default App;
