import TagManager from "react-gtm-module";

// init google tag manager
const gtmId = process.env.REACT_APP_GOOGLE_TAGMANAGER_ID || "";

/**
 * Intialize google tag manager
 */
export const initializeGoogleTagManager = (): void => {
    TagManager.initialize({ gtmId });
};

/**
 * Helper function to set traffic type in GTM
 * @param type
 */
export const googleTagSetTrafficType = (type: string): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'setTrafficType',
        traffic_type: type,
      },
    });
};
  