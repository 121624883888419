import { Address, SalesOrgDivision } from "../models/UserAccount";
// import moment from "moment";
import { format,isDate,toDate } from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import { es } from "date-fns/locale";

export const formatAddress = (address: Partial<Address>): string[] => {
  let results: string[] = [];
  if (address.housenumber) {
    results.push(`${address.housenumber}`);
  }
  if (
    address.deliveryLine ||
    address.address ||
    address.address1 ||
    address.street
  ) {
    results.push(
      `${
        address.deliveryLine ||
        address.address ||
        address.address1 ||
        address.street
      }`
    );
  }
  if (address.address2) {
    results.push(address.address2);
  }
  if (address.city && address.postal) {
    let line2 =
      address.region === ""
        ? `${address.city}, ${address.postal}`
        : `${address.city}, ${address.region} ${address.postal}`;
    results.push(line2);
  }
  if (address.country && address.country !== "US") {
    results.push(`${address.country}`);
  }
  if (
    address.poBox &&
    typeof address.poBox !== "string" &&
    address.poBox.number !== ""
  ) {
    results.push(`P.O. Box ${address.poBox.number}`);
    let line = "";
    if (address.poBox.region !== "") {
      line = `${address.poBox.city}, ${address.poBox.region} ${address.poBox.postal}`;
    } else {
      line = `${address.poBox.city}, ${address.poBox.postal}`;
    }
    results.push(line);
    if (address.poBox.country) {
      results.push(`${address.poBox.country}`);
    }
  }

  return results;
};

export const formatCurrency = (
  amount: string | number,
  currency: string
): string => {
  const rawAmount = Number(amount);
   if (isNaN(rawAmount)) {
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: currency || "USD",
      currencySign: "accounting",
    }).format(0);
  }

  const negative = rawAmount < 0;
  const adjustedAmount = Math.abs(rawAmount);
  
  return `${negative ? "-" : ""}${new Intl.NumberFormat("en", {
    style: "currency",
    currency: currency || "USD",
    currencySign: "accounting",
  }).format(adjustedAmount)}`;
};

export const formatUnitPrice = (
  unitPrice: string,
  unitCount: string,
  salesUnit: string,
  currency: string
): string => {
  return `${formatCurrency(unitPrice, currency)} / ${parseInt(
    unitCount
  )} ${salesUnit}`;
};

export const formatPercentage = (percentage: string) => {
  let wholePercentage = parseFloat(percentage?.replace("%", ""));
  if (wholePercentage < 0) {
    return `-${Math.abs(wholePercentage).toFixed(2)}%`;
  }
  return `${wholePercentage.toFixed(2)}%`;
};

export const formatLastLogon = (lastLogon: string): string => {
  // let lastLogonMoment = moment.utc(lastLogon);
  let lastLogonMoment = zonedTimeToUtc(new Date(lastLogon),'UTC');
  // isDate(toDate(lastLogonMoment))
  if (isDate(toDate(lastLogonMoment)) === false || lastLogonMoment.getFullYear() === 1753) {
    return "None";
  } else {
    // return lastLogonMoment.local().format("M/DD/YYYY h:mma");
    return format(toDate(lastLogonMoment),"M/dd/yyyy h:mma",{locale:es});
  }
};

export const formatBrandId = (brand: SalesOrgDivision) => {
  return `${brand.salesOrg?.trim()}-${brand.division.trim()}`;
};

export const trimLeadingZeros = (x: string) => {
  return x?.replace(/^0+/, "");
};
