import { useEffect, useState, FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useCookies } from "react-cookie";

const BetaToggleHeader: FC = (): ReactNode => {
  const [cookies, setCookies] = useCookies(["versionCookie"]);
  const [toggle, setToggle] = useState(false);
  // URLs
  const versionOneURL: string = process.env.REACT_APP_VERSION_ONE_URL || "";
  const versionTwoURL: string = process.env.REACT_APP_VERSION_TWO_URL || "";
  const showToggle: boolean = process.env.REACT_APP_SHOW_BETA_TOGGLE === "true";

  // set state based on cookie and redirect if needed
  // or initialize the cookie
  useEffect(() => {
    if (cookies.versionCookie !== "one") {
      setCookies("versionCookie", "one", { path: "/" });
      setToggle(false);
    } else {
      if (cookies.versionCookie === "one") {
        setToggle(false);
      } else {
        setToggle(true);
      }
    }
  }, [cookies.versionCookie, setCookies]);

  // handle toggle: set cookie and
  // redirect to correct version in correct environment
  const onToggleAction = (): void => {
    if (!toggle) {
      setCookies("versionCookie", "two", { path: "/" });
      window.location.href = versionTwoURL;
    } else {
      setCookies("versionCookie", "one", { path: "/" });
      window.location.href = versionOneURL;
    }
    setToggle(toggle);
  };
  return (
    <>
      {showToggle && (
        <div className="beta-header">
          <Form>
            <Form.Check
              reverse
              type="switch"
              id="beta-switch"
              label={
                cookies.versionCookie === "two"
                  ? "Updated User Experience"
                  : "Try the Updated User Experience"
              }
              className="text-white beta-switch"
              defaultChecked={toggle}
              onChange={onToggleAction}
            />
          </Form>
        </div>
      )}
    </>
  );
};
export default BetaToggleHeader;
